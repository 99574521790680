"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _SelectInput = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/form/SelectInput"));
var _TextInput = require("@oberoninternal/travelbase-ds/components/form/TextInput");
var _Body = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/primitive/Body"));
var _grid = require("@rebass/grid");
var _react = _interopRequireDefault(require("react"));
var _reactIntl = require("react-intl");
var _styledComponents = _interopRequireDefault(require("styled-components"));
var Yup = _interopRequireWildcard(require("yup"));
var _messages = _interopRequireDefault(require("../constants/messages"));
var _Fieldset = _interopRequireDefault(require("./designsystem/Fieldset"));
var _StyledCaret = _interopRequireDefault(require("./designsystem/StyledCaret"));
var _Formed = _interopRequireDefault(require("./Formed"));
var _Stack = _interopRequireDefault(require("./Stack"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const reviewMessages = (0, _reactIntl.defineMessages)({
  '10': {
    "id": "7ebrDm",
    "defaultMessage": [{
      "type": 0,
      "value": "10. Uitmuntend"
    }]
  },
  '9': {
    "id": "E4OO49",
    "defaultMessage": [{
      "type": 0,
      "value": "9. Fantastisch"
    }]
  },
  '8': {
    "id": "6Vub1M",
    "defaultMessage": [{
      "type": 0,
      "value": "8. Erg goed"
    }]
  },
  '7': {
    "id": "Ib1UHU",
    "defaultMessage": [{
      "type": 0,
      "value": "7. Goed"
    }]
  },
  '6': {
    "id": "Tu5zXb",
    "defaultMessage": [{
      "type": 0,
      "value": "6. Voldoende"
    }]
  },
  '5': {
    "id": "q7aEfp",
    "defaultMessage": [{
      "type": 0,
      "value": "5. Matig"
    }]
  },
  '4': {
    "id": "ZTFLDr",
    "defaultMessage": [{
      "type": 0,
      "value": "4. Zeer matig"
    }]
  },
  '3': {
    "id": "lJe3Ji",
    "defaultMessage": [{
      "type": 0,
      "value": "3. Slecht"
    }]
  },
  '2': {
    "id": "AtGZfx",
    "defaultMessage": [{
      "type": 0,
      "value": "2. Zeer slecht"
    }]
  },
  '1': {
    "id": "Z4Uryo",
    "defaultMessage": [{
      "type": 0,
      "value": "1. Verschikkelijk"
    }]
  }
});
const travelGroupMessages = (0, _reactIntl.defineMessages)({
  ALONE: {
    "id": "eN1gWX",
    "defaultMessage": [{
      "type": 0,
      "value": "Alleen"
    }]
  },
  BUSINESS_TRIP: {
    "id": "UWhcol",
    "defaultMessage": [{
      "type": 0,
      "value": "Zakenreis"
    }]
  },
  FAMILY_WITH_OLDER_CHILDREN: {
    "id": "1moRxY",
    "defaultMessage": [{
      "type": 0,
      "value": "Familie met oudere kinderen"
    }]
  },
  FAMILY_WITH_YOUNG_CHILDREN: {
    "id": "NiE/xz",
    "defaultMessage": [{
      "type": 0,
      "value": "Familie met jongere kinderen"
    }]
  },
  GROUP_OF_FRIENDS: {
    "id": "3AD3r7",
    "defaultMessage": [{
      "type": 0,
      "value": "Vriendengroep"
    }]
  },
  GROUP_TRIP: {
    "id": "WCH9ft",
    "defaultMessage": [{
      "type": 0,
      "value": "Groepsreis"
    }]
  },
  OLDER_COUPLE: {
    "id": "d/QMgN",
    "defaultMessage": [{
      "type": 0,
      "value": "Ouder stel"
    }]
  },
  YOUNG_COUPLE: {
    "id": "RXsiSX",
    "defaultMessage": [{
      "type": 0,
      "value": "Jong stel"
    }]
  }
});
const getTravelGroupOptions = intl => Object.entries(travelGroupMessages).map(([key, value]) => ({
  label: intl.formatMessage(value),
  value: key
}));
const getReviewOptions = intl => Object.keys(reviewMessages).reverse().map(key => ({
  value: Number(key),
  label: intl.formatMessage(reviewMessages[key])
}));
const initialValues = {
  title: '',
  ratingLocation: '',
  ratingHospitality: '',
  ratingLayout: '',
  ratingMaintenance: '',
  ratingValueForMoney: '',
  comment: '',
  tips: '',
  travelGroupType: ''
};
const ReviewForm = ({
  handleSubmit
}) => {
  const intl = (0, _reactIntl.useIntl)();
  const reviewOptions = getReviewOptions(intl);
  const travelGroupTypeOptions = getTravelGroupOptions(intl);
  const validationSchema = Yup.object().shape({
    title: Yup.string().max(65, intl.formatMessage(_messages.default.maximumCharsError, {
      maxLength: 65
    })).required(intl.formatMessage(_messages.default.fieldError)),
    ratingLocation: Yup.number().required(intl.formatMessage(_messages.default.fieldError)),
    ratingHospitality: Yup.number().required(intl.formatMessage(_messages.default.fieldError)),
    ratingLayout: Yup.number().required(intl.formatMessage(_messages.default.fieldError)),
    ratingMaintenance: Yup.number().required(intl.formatMessage(_messages.default.fieldError)),
    ratingValueForMoney: Yup.number().required(intl.formatMessage(_messages.default.fieldError)),
    comment: Yup.string().max(500, intl.formatMessage(_messages.default.maximumCharsError, {
      maxLength: 500
    })).required(intl.formatMessage(_messages.default.fieldError)),
    tips: Yup.string(),
    travelGroupType: Yup.string().required(intl.formatMessage(_messages.default.fieldError))
  });
  return __jsx(_Formed.default, {
    initialValues: initialValues,
    validationSchema: validationSchema,
    handleSubmit: handleSubmit
  }, ({
    isSubmitting
  }) => __jsx(_react.default.Fragment, null, __jsx(_grid.Box, {
    mb: [2, 5]
  }, __jsx(BoldBody, null, __jsx(_reactIntl.FormattedMessage, {
    id: "k1V6bO",
    defaultMessage: [{
      "type": 0,
      "value": "Geef s.v.p. een korte zin die de beoordeling van je accommodatie samenvat."
    }]
  })), __jsx(NeutralBody, {
    variant: "small"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "fzRn8D",
    defaultMessage: [{
      "type": 0,
      "value": "Bijvoorbeeld: “Mijn tweede huis”, “Voor herhaling vatbaar”"
    }]
  })), __jsx(SummaryInputField, {
    name: "title"
  })), __jsx(_grid.Box, {
    mb: ['2rem', 8]
  }, __jsx(_Fieldset.default, {
    variant: "large",
    label: __jsx(_reactIntl.FormattedMessage, {
      id: "N1Vm0N",
      defaultMessage: [{
        "type": 0,
        "value": "Geef aan wat je reisgezelschap was"
      }]
    }),
    id: "travelGroupType"
  }, __jsx(TravelGroupTypeSelectInput, {
    name: "travelGroupType",
    hideChoices: true,
    ToggleComponent: _StyledCaret.default,
    options: travelGroupTypeOptions,
    placeholder: __jsx(_reactIntl.FormattedMessage, {
      id: "EB8xMM",
      defaultMessage: [{
        "type": 0,
        "value": "Kies een reisgezelschap"
      }]
    })
  }))), __jsx(BoldBody, null, __jsx(_reactIntl.FormattedMessage, {
    id: "rar5Dm",
    defaultMessage: [{
      "type": 0,
      "value": "Beoordeel de volgende eigenschappen met een cijfer"
    }]
  })), __jsx(_Stack.default, {
    mt: "2.4rem",
    spacing: 3,
    mb: [0, 7]
  }, __jsx(_Fieldset.default, {
    variant: "large",
    label: __jsx(_reactIntl.FormattedMessage, {
      id: "YqIu5E",
      defaultMessage: [{
        "type": 0,
        "value": "Wat vind je van de ligging van de accommodatie?"
      }]
    }),
    id: "ratingLocation"
  }, __jsx(ReviewSelectInput, {
    name: "ratingLocation",
    options: reviewOptions,
    hideChoices: true,
    ToggleComponent: _StyledCaret.default,
    placeholder: __jsx(_reactIntl.FormattedMessage, {
      id: "oRjnDy",
      defaultMessage: [{
        "type": 0,
        "value": "Kies een cijfer"
      }]
    })
  })), __jsx(_Fieldset.default, {
    variant: "large",
    label: __jsx(_reactIntl.FormattedMessage, {
      id: "VhG9dB",
      defaultMessage: [{
        "type": 0,
        "value": "Hoe vind je de gastvrijheid van de gastheer/-vrouw?"
      }]
    }),
    id: "ratingHospitality"
  }, __jsx(ReviewSelectInput, {
    name: "ratingHospitality",
    options: reviewOptions,
    hideChoices: true,
    ToggleComponent: _StyledCaret.default,
    placeholder: __jsx(_reactIntl.FormattedMessage, {
      id: "oRjnDy",
      defaultMessage: [{
        "type": 0,
        "value": "Kies een cijfer"
      }]
    })
  })), __jsx(_Fieldset.default, {
    variant: "large",
    label: __jsx(_reactIntl.FormattedMessage, {
      id: "fK19QK",
      defaultMessage: [{
        "type": 0,
        "value": "Wat vind je van de inrichting (meubels, bedden, servies)?"
      }]
    }),
    id: "ratingLayout"
  }, __jsx(ReviewSelectInput, {
    name: "ratingLayout",
    options: reviewOptions,
    hideChoices: true,
    ToggleComponent: _StyledCaret.default,
    placeholder: __jsx(_reactIntl.FormattedMessage, {
      id: "oRjnDy",
      defaultMessage: [{
        "type": 0,
        "value": "Kies een cijfer"
      }]
    })
  })), __jsx(_Fieldset.default, {
    variant: "large",
    label: __jsx(_reactIntl.FormattedMessage, {
      id: "mwwbQ1",
      defaultMessage: [{
        "type": 0,
        "value": "Hoe vind je de staat van onderhoud en de schoonmaak van de accommodatie?"
      }]
    }),
    id: "ratingMaintenance"
  }, __jsx(ReviewSelectInput, {
    name: "ratingMaintenance",
    options: reviewOptions,
    hideChoices: true,
    ToggleComponent: _StyledCaret.default,
    placeholder: __jsx(_reactIntl.FormattedMessage, {
      id: "oRjnDy",
      defaultMessage: [{
        "type": 0,
        "value": "Kies een cijfer"
      }]
    })
  })), __jsx(_Fieldset.default, {
    variant: "large",
    label: __jsx(_reactIntl.FormattedMessage, {
      id: "8nb235",
      defaultMessage: [{
        "type": 0,
        "value": "Vind je de prijs en de kwaliteit van de accommodatie in goede verhouding?"
      }]
    }),
    id: "ratingValueForMoney"
  }, __jsx(ReviewSelectInput, {
    name: "ratingValueForMoney",
    options: reviewOptions,
    hideChoices: true,
    ToggleComponent: _StyledCaret.default,
    placeholder: __jsx(_reactIntl.FormattedMessage, {
      id: "oRjnDy",
      defaultMessage: [{
        "type": 0,
        "value": "Kies een cijfer"
      }]
    })
  }))), __jsx(_grid.Box, {
    mb: 7
  }, __jsx(_Body.default, null, __jsx(_reactIntl.FormattedMessage, {
    id: "J3TG53",
    defaultMessage: [{
      "type": 0,
      "value": "Toelichting"
    }]
  })), __jsx(NeutralBody, {
    variant: "small"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "rkJ1mF",
    defaultMessage: [{
      "type": 0,
      "value": "Geef desgewenst een verdere toelichting. Bijvoorbeeld: “alles was schoon”,”de locatie was prachtig”, “ het ontbijt was uitgebreid en lekker”,”de schoonmaak laat te wensen over”. Graag minimaal 1 zin."
    }]
  })), __jsx(TextArea, {
    name: "comment"
  })), __jsx(_grid.Box, null, __jsx(BoldBody, null, __jsx(_reactIntl.FormattedMessage, {
    id: "b+q7X2",
    defaultMessage: [{
      "type": 0,
      "value": "Tip voor de gastheer ter verbetering van de accommodatie"
    }]
  })), __jsx(NeutralBody, {
    variant: "small"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "8fHYOW",
    defaultMessage: [{
      "type": 0,
      "value": "Deze tip sturen we direct door naar de gastheer en tonen we niet op de website. "
    }, {
      "type": 1,
      "value": "br"
    }, {
      "type": 0,
      "value": " Bijvoorbeeld: “het lampje bij het aanrecht werkte niet”, ”de batterij van de klok is leeg”, “ga vooral zo door!”."
    }],
    values: {
      br: __jsx("br", null)
    }
  })), __jsx(TextArea, {
    name: "tips"
  })), __jsx(PublishButton, {
    size: "large",
    type: "submit",
    submitting: isSubmitting
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "EAHVhY",
    defaultMessage: [{
      "type": 0,
      "value": "Beoordeling publiceren"
    }]
  })), __jsx(NeutralBody, {
    variant: "small"
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "Oj0TRM",
    defaultMessage: [{
      "type": 0,
      "value": "Je beoordeling is direct zichtbaar op de website"
    }]
  }))));
};
const NeutralBody = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "ReviewForm__NeutralBody",
  componentId: "sc-15ifjop-0"
})(["color:", ";margin-bottom:2rem;"], ({
  theme
}) => theme.colors.neutral['50']);
const BoldBody = (0, _styledComponents.default)(_Body.default).withConfig({
  displayName: "ReviewForm__BoldBody",
  componentId: "sc-15ifjop-1"
})(["font-weight:600;margin-bottom:", ";"], ({
  theme
}) => theme.spacing['20_Tiny']);
const PublishButton = (0, _styledComponents.default)(_Button.default).withConfig({
  displayName: "ReviewForm__PublishButton",
  componentId: "sc-15ifjop-2"
})(["margin-bottom:1rem;margin-top:", ";width:100%;@media screen and (min-width:", "){width:unset;}@media screen and (min-width:", "){margin-top:", ";}"], ({
  theme
}) => theme.spacing['50_Semi'], ({
  theme
}) => theme.mediaQueries.xs, ({
  theme
}) => theme.mediaQueries.m, ({
  theme
}) => theme.spacing['80_XXLarge']);
const TextArea = (0, _styledComponents.default)(_TextInput.TextInputField).attrs({
  type: 'textarea'
}).withConfig({
  displayName: "ReviewForm__TextArea",
  componentId: "sc-15ifjop-3"
})(["min-height:19.2rem;"]);
const TravelGroupTypeSelectInput = (0, _styledComponents.default)(_SelectInput.default).withConfig({
  displayName: "ReviewForm__TravelGroupTypeSelectInput",
  componentId: "sc-15ifjop-4"
})(["max-width:60rem;"]);
const ReviewSelectInput = (0, _styledComponents.default)(_SelectInput.default).withConfig({
  displayName: "ReviewForm__ReviewSelectInput",
  componentId: "sc-15ifjop-5"
})(["max-width:20.8rem;"]);
const SummaryInputField = (0, _styledComponents.default)(_TextInput.TextInputField).withConfig({
  displayName: "ReviewForm__SummaryInputField",
  componentId: "sc-15ifjop-6"
})(["@media screen and (max-width:", "){min-height:14.4rem;}"], ({
  theme
}) => theme.mediaQueries.s);
var _default = exports.default = ReviewForm;